import React, {useContext} from 'react'
import './style.scss'
import NaviContext from '../../context/NaviContext'

function MainFrameCover() {
	const naviContext = useContext(NaviContext)

  return (
		<div id="mainframe-cover" aria-label="Toggle to hide away navigation" className={`layer ${naviContext.isActive ? 'active' : ''}`} onClick={() => { naviContext.setActive(false); naviContext.setHamburgerActive(false)}} onKeyDown={() => { naviContext.setActive(true); naviContext.setHamburgerActive(false)}} role="button" tabIndex={0}>
			<div id="mainframe-bg-overlay" className="layer"></div>
		</div>
  )
}

export default MainFrameCover