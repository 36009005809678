import React, {useContext} from 'react'
import './style.scss'
import {Link} from 'gatsby'
import { useInView } from 'react-intersection-observer'

import Intro from '../../animations/Intro'
import NaviContext from '../../context/NaviContext'

function Footer(props) {
	const [io, ioInView] = useInView({ triggerOnce: true })
	const naviContext = useContext(NaviContext)

	// const image = props.image
	const delayStep = 85
	const baseDelay0 = 800
	const baseDelay1 = 200
	const baseDelay2 = 500

	const d = new Date()
	const year = d.getFullYear()

  // const data = useStaticQuery(graphql`
	// {
	// 	contentfulGeneral(title: {eq: "1/ST"}) {
	// 		id
	// 		copy
	// 		secondaryNavigation {
	// 			id
	// 			title
	// 			url
	// 			external
	// 		}
	// 	}
	// }
  // `)

  // const naviItems = data.contentfulGeneral.secondaryNavigation.map(prepNavi)

  // function prepNavi(item, index) {
  // 	if(data.contentfulGeneral.secondaryNavigation.length === index + 2) {
  // 		if(data.contentfulGeneral.secondaryNavigation[index].external) {
	//   		return <li key={item.id} className="navi-item"><span className="line"></span><a href={item.url} target="_blank" rel="noopener noreferrer">{item.title}</a></li>
  // 		}
  // 		return <li key={item.id} className="navi-item"><span className="line"></span><Link to={item.url}>{item.title}</Link></li>
  // 	}
	// if(data.contentfulGeneral.secondaryNavigation[index].external) {
	// 	return (
	// 		<li key={item.id} className="navi-item"><a href={item.url} target="_blank" rel="noopener noreferrer">{item.title}</a></li>
	// 	)
	// }
  // 	return (
  // 		<li key={item.id} className="navi-item"><Link to={item.url}>{item.title}</Link></li>
  // 	)
  // }

  return (
		<footer ref={io} id="master-footer" className="c5">

			<a href="#top" className="decorative-arrow up white" aria-label="Go to top">&nbsp;</a>
			<div className="footer-navi-wrap fs-85">
				<div className="footer-logo">
					<Intro visible={ioInView} in={{ border: 500, blink: 500 }} delayIn={baseDelay0} mounted={true} stay={true} className={`footer-logo-positioner c5 t border-bottom-off ${naviContext.onMobile ? '' : 'border-right-off border-left-off'}`}>
						<div className="footer-logo-sizer">
							{/* <img src={footerData?.logoSrc} className={'img-contain lazyload fade'} alt="1/ST Logo" /> */}
						</div>
					</Intro>
				</div>
				
				<div className="footer-navi">
					<Intro visible={ioInView} in={{ border: 500, blink: 500 }} delayIn={baseDelay1 + delayStep * 0} mounted={true} stay={true} className="navi-item navi-item-copy c5 t border-bottom-off">
						{/* {data.contentfulGeneral.copy} */}
						&copy;{year} 1/ST
					</Intro>
					{/* {naviItems} */}
					<Intro visible={ioInView} in={{ border: 500, blink: 500 }} delayIn={baseDelay1 + delayStep * 1} mounted={true} stay={true} className="navi-item c5 t border-bottom-off">
						<Link to={'/privacy-policy/'}>Privacy Policy</Link>
					</Intro>
					<Intro visible={ioInView} in={{ border: 500, blink: 500 }} delayIn={baseDelay1 + delayStep * 2} mounted={true} stay={true} className="navi-item c5 t border-bottom-off">
						<Link to={'/careers/'}>Careers</Link>
					</Intro>
					{/* <Intro visible={ioInView} in={{ border: 500, blink: 500 }} delayIn={baseDelay1 + delayStep * 3} mounted={true} stay={true} className="navi-item c5 t border-bottom-off">
						<Link to={'/horse-care/'}>Horse Care</Link>
					</Intro> */}
					<Intro visible={ioInView} in={{ border: 500, blink: 500 }} delayIn={baseDelay1 + delayStep * 3} mounted={true} stay={true} className="navi-item c5 t border-bottom-off">
						<Link to={'/contact/'}>Contact Us</Link>
					</Intro>
					<Intro visible={ioInView} in={{ border: 500, blink: 500 }} delayIn={baseDelay1 + delayStep * 4} mounted={true} stay={true} className="navi-item c5 t border-bottom-off">
						<Link to={'/news/'}>Press Room</Link>
					</Intro>
					<Intro visible={ioInView} in={{ border: 500, blink: 500 }} delayIn={baseDelay1 + delayStep * 5} mounted={true} stay={true} className="navi-item c5 t border-bottom-off">
						<span className="line"></span><a target="_blank" rel='noreferrer noopener' href={'https://1st.com/bet/gulfstream-park?utm_source=gsp&utm_medium=track_referral&utm_campaign=1st_bet_racing_bet_now&utm_content=1st_bet_racing_bet_now_gsp_button'}>Download 1/ST Bet</a>
					</Intro>
					<Intro visible={ioInView} in={{ border: 500, blink: 500 }} delayIn={baseDelay1 + delayStep * 6} mounted={true} stay={true} className="navi-item c5 t border-bottom-off">
						<Link to={'/leasing-info/'}>Leasing info</Link>
					</Intro>
					<Intro visible={ioInView} in={{ border: 500, blink: 500 }} delayIn={baseDelay1 + delayStep * 7} mounted={true} stay={true} className="navi-item c5 t border-bottom-off">
						<Link to={'/responsible-gaming/'}>Responsible Gaming</Link>
					</Intro>
					<Intro visible={ioInView} in={{ border: 500, blink: 500 }} delayIn={baseDelay1 + delayStep * 8} mounted={true} stay={true} className="navi-item c5 t border-bottom-off">
						<a href={'https://deepsleepstudio.com'} target="_blank" rel="noreferrer noopener">Site By</a>
					</Intro>
				</div>
				
				<div className="footer-navi secondary">
					<Intro visible={ioInView} in={{ border: 500, blink: 500 }} delayIn={baseDelay2 + delayStep * 0} mounted={true} stay={true} className={`navi-item c5 t  navi-item-copy border-bottom-off ${naviContext.onMobile ? '': 'border-left-off'}`}>
						Site Map
					</Intro>
					<Intro visible={ioInView} in={{ border: 500, blink: 500 }} delayIn={baseDelay2 + delayStep * 1} mounted={true} stay={true} className={`navi-item c5 t border-bottom-off ${naviContext.onMobile ? '': 'border-left-off'}`}>
						<Link to={'/racing-information/'}>Racing</Link>
					</Intro>
					<Intro visible={ioInView} in={{ border: 500, blink: 500 }} delayIn={baseDelay2 + delayStep * 3} mounted={true} stay={true} className={`navi-item c5 t border-bottom-off ${naviContext.onMobile ? '': 'border-left-off'}`}>
						<Link to={'/events/'}>Events</Link>
					</Intro>
					<Intro visible={ioInView} in={{ border: 500, blink: 500 }} delayIn={baseDelay2 + delayStep * 4} mounted={true} stay={true} className={`navi-item c5 t border-bottom-off ${naviContext.onMobile ? '': 'border-left-off'}`}>
						<Link to={'/dining/'}>Dining</Link>
					</Intro>
					<Intro visible={ioInView} in={{ border: 500, blink: 500 }} delayIn={baseDelay2 + delayStep * 5} mounted={true} stay={true} className={`navi-item c5 t border-bottom-off ${naviContext.onMobile ? '': 'border-left-off'}`}>
						<Link to={'/shopping/'}>Shopping</Link>
					</Intro>
					<Intro visible={ioInView} in={{ border: 500, blink: 500 }} delayIn={baseDelay2 + delayStep * 6} mounted={true} stay={true} className={`navi-item c5 t border-bottom-off ${naviContext.onMobile ? '': 'border-left-off'}`}>
						<a href="#modal-1st-subscribe">Subscribe</a>
					</Intro>
          <Intro visible={ioInView} in={{ border: 500, blink: 500 }} delayIn={baseDelay2 + delayStep * 7} mounted={true} stay={true} className={`navi-item c5 t border-bottom-off ${naviContext.onMobile ? '': 'border-left-off'}`}>
						<Link to={'/driving-directions/'}>Directions</Link>
					</Intro>
				</div>

			</div>
	
		</footer>
  )
}

export default Footer