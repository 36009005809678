import React, { useState, memo } from 'react'
import './style.scss'
import BlocksRouter from '../Sections/BlocksRouter'
import DateBlock from '../DateBlock'
import Button from '../Blocks/Button'
import Intro from '../../animations/Intro'
import Slider from 'react-slick'
import FeaturedEvents from '../Blocks/Custom/FeaturedEvents'
import { GatsbyImage } from 'gatsby-plugin-image'

function Event(props) {

  const { singleEvent } = props

  const [currentGalleryImage, setCurrentGalleryImage] = useState(0)

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 3
  };

  const todayDate = new Date()

  todayDate.setDate(todayDate.getDate() - 1)


  // Make sure the events are sorted
  const eventDayCollectinSorted = singleEvent?.eventday?.slice() // Copy first to avoid 0 is read only error
  eventDayCollectinSorted?.sort(function (a, b) { // sort
    return new Date(a.date) - new Date(b.date)
  })

  const occurence = eventDayCollectinSorted?.filter((node, i) => {
    const day = new Date(node.date.replace(/-/g, '\/').replace(/T.+/, ''))
    return todayDate > day ? false : true
  })
    ?.map((node, i) => {
      const day = new Date(node.date.replace(/-/g, '\/').replace(/T.+/, ''))
      const passed = todayDate > day ? true : false
      return <DateBlock key={i} ii={i} day={day} passed={passed} animate={true} className={'c1'} />
    })

  const buttons = singleEvent?.buttons?.map((node, i) => {
    return <Button key={i} link={node.value} label={node.key} classes="" />
  })

  const formEvent = singleEvent?.formUrl ?
    <iframe
      src={singleEvent.formUrl}
      frameBorder="0"
      style={{ order: '100', width: '100vw', maxWidth: '100%', padding: '1em 0', margin: '0 auto', minHeight: singleEvent.formHeight ? singleEvent.formHeight + 'px' : '500px', height: singleEvent.formHeight ? singleEvent.formHeight + 'px' : '500px' }}
      title="Contact Form Event"
    />
    :
    null


  // Building up data block for invoking this page layout and content
  const blocksHero = singleEvent ? [
    {
      __typename: 'ContentfulBlockImage',
      classes: 'flex ai-flex-start jc-flex-start border-left-off',
      image: {
        gatsbyImageData: singleEvent?.featuredImage?.gatsbyImageData,
        description: singleEvent?.featuredImage?.description,
      },
    },
    {
      __typename: 'ContentfulBlockWysiwyg',
      classes: 'flex ai-flex-end jc-flex-end text-right',
      content: singleEvent.excerpt,
    },
    {
      __typename: 'tosection',
      toSection: 'event-more',
    },
  ] : null

  // Building up data block for invoking this page layout and content
  const blocksMore = singleEvent ? [
    {
      __typename: 'ContentfulBlockWysiwyg',
      classes: `flex padd span-8 ${!singleEvent.aside ? 'js-center' : ''} span-12-tablet c1`,
      content: { ...singleEvent.content, jsx: formEvent },
    },
    {
      __typename: 'ContentfulBlockWysiwyg',
      classes: 'aside flex padd c3 span-4 span-12-tablet',
      content: singleEvent.aside,
    },
  ] : null

  const galleryFeaturedImage = singleEvent?.gallery?.map((nodenode, i) => {

    if (i === currentGalleryImage)
      return (
        <div key={i} className="image-box">
          <Intro visible={i === currentGalleryImage} in={{ fade: 270 }} key={``} className={``}>
            <div className={`aspect-ratio`}>
              <GatsbyImage
                image={nodenode.gatsbyImageData}
                imgStyle={{ objectFit: 'cover' }}
                objectPosition='50% 50%'
                title={nodenode.title}
                alt={nodenode.description}
              />
            </div>
          </Intro>
        </div>
      )
    else
      return null
  })

  const galleryItems = singleEvent?.gallery?.map((nodenode, i) => {
    return (
      <div key={i} onClick={() => setCurrentGalleryImage(i)} onKeyDown={() => setCurrentGalleryImage(i)} className={`image-box ${i === currentGalleryImage ? 'current' : ''}`} role="button" aria-label="Gallery selector" tabIndex={0}>
        <div className={`aspect-ratio`}>
          <GatsbyImage
            image={nodenode.gatsbyImageData}
            imgStyle={{ objectFit: 'cover' }}
            objectPosition='50% 50%'
            title={nodenode.title}
            alt={nodenode.description}
          />
        </div>
      </div>
    )
  })


  return (
    <>
      <section key={`event-hero`} className={`c0 section-hero section-event-hero`}>
        <div id="event-hero" className="anchor"></div>
        <BlocksRouter blocks={blocksHero} />
      </section>
      <section key={`event-more`} className={`c0 section-event-more flex-12 padd-top`}>
        <div id="event-more" className="anchor"></div>
        <div className="details flex-12">
          <div className={`occurencies span-${buttons ? '8' : '12'} span-12-tablet`}>
            {occurence?.length !== 1 ?
              <div className="intro c2 padd-left padd-right">
                <h4>on:</h4>
              </div>
              :
              null
            }
            <div className="box padd-left">
              {occurence}
            </div>
          </div>
          {buttons ?
            <div className="cta c5 span-4 span-12-tablet">
              {buttons}
            </div>
            : null}
        </div>
        <BlocksRouter blocks={blocksMore} />
      </section>
      {galleryItems ?
        <section key={`event-gallery`} className={`c5 flex-12 section-gallery padd-top padd-bottom section-event-gallery`}>
          <div id="event-gallery" className="anchor"></div>
          <div className="gallery">
            <div className="gallery-featured">
              {galleryFeaturedImage}
            </div>
            <div className="gallery-thumbnails">
              <Slider {...settings}>
                {galleryItems}
              </Slider>
            </div>
          </div>
        </section>
        : null}
      <section key={`event-featured`} className={`c0 section-featured-events beige-stripes featured-events section-event-featured `}>
        <div id="featured-events" className="anchor"></div>
        <FeaturedEvents />
      </section>
    </>
  )
}

export default memo(Event)