import React, {useState, useEffect} from 'react'
import './style.scss'
import { Link } from 'gatsby';

const setCookie = (cookieName, cookieValue, expDays) => {
  const d = new Date();
  d.setTime(d.getTime() + (expDays*24*60*60*1000));
  let expires = "expires=" + d.toUTCString();
  document.cookie = cookieName + "=" + cookieValue + ";" + expires + ";path=/";
}

const getCookie = (cookieName) => {
  let name = cookieName + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function Cookies() {
  const [cookieState, setCookieState] = useState(true)

  function checkCookie() {
    let consent = getCookie("consent");
    if (consent === "") 
      setCookieState(false)
    else 
      setCookieState(true)
  }

  useEffect(() => {
    checkCookie()
  }, [cookieState])


    return (
      !cookieState ?
        <div className='cookie-box'>
          <div className='content padd-half'>
            <p>
              By clicking “OK”,
              you consent to our website's use of cookies to provide you with the most relevant experience by remembering your cookie preferences. 
              You automatically accept this and may read our <Link to="/privacy-policy/">Privacy Policy</Link>
            </p>
            <div className='btn small c5' onClick={() =>{ setCookie("consent", true, 365); setCookieState(true) }}><span>OK</span></div>
          </div>
        </div>  
      :
      null
    )
}

export default Cookies

