import React, { useState } from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'

import Intro from '../../../animations/Intro'
// import FormSubscribe from '../../Form/Klaviyo/Subscribe'

function SubscribeBox(props) {
  const [io, ioInView] = useInView({ triggerOnce: true })
  const [iframeHeight, setIframeHeight] = useState()

  return (
    <Intro visible={ioInView} in={{ bg: 600, fade: 400 }} delayIn={500} mounted={true} stay={true} className="c0  t">
      <div ref={io} className={`block-login-box c0 fs-85 t ${props.classes}`}>

        {/* <div className="logo-1st"></div> */}

        <h3>Subscribe</h3>
        {/* <FormSubscribe /> */}
        <iframe
          onLoad={(ele) => {
            setIframeHeight(ele?.target?.scrollHeight)
          }}
          src="https://mcdg8xmk7lvyntmhlr6vs3w15q1m.pub.sfmc-content.com/qzc1g31w2o1"
          frameBorder="0"
          style={{ backgroundColor: '#ffffff', minHeight: '590px', height: iframeHeight }}
          title="Email subscription form"
        />

      </div>
    </Intro>
  )
}

export default SubscribeBox