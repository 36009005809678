import React, { useContext } from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'
import Wysiwyg from '../Wysiwyg'
import Intro from '../../../animations/Intro'
import { Link } from 'gatsby'
import ModalContext from '../../../context/ModalContext'


function validURL(str) {
  var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return !!pattern.test(str);
}

function CustomBox(props) {
  const [io, ioInView] = useInView({ triggerOnce: true })

  const modalContext = useContext(ModalContext)

  return (props?.popup?.link && validURL(props.popup.link) ?
    <Intro visible={ioInView} in={{ bg: 600, fade: 400 }} delayIn={500} mounted={true} stay={true} className="c0 custom-popup t"
      onClick={() => { { modalContext.setActive(false); modalContext.setTrigger(old => !old) } }}
      onKeyDown={() => { modalContext.setActive(false); modalContext.setTrigger(old => !old) }}
    >
      <a href={props?.popup?.link} className="nostrike" target="_blank" rel="noreferrer noopener">
        <div ref={io} className={`block-login-box c0 fs-85 t ${props.classes}`}>
          <Wysiwyg content={props?.popup?.content} />
          { props?.popup?.formUrl ?
            <iframe
              src={props?.popup?.formUrl}
              frameBorder="0"
              style={{ backgroundColor: '#f0efee', width: '100vw', maxWidth: '100%', padding: '1em 0', margin: '0 auto', minHeight: props?.popup?.formHeight ? props?.popup?.formHeight + 'px' : '500px', height: props?.popup?.formHeight ? props?.popup?.formHeight + 'px' : '500px' }}
              title="Contact Form Event"
            />
            :
            null
          }
        </div>
      </a>
    </Intro>
    : props?.popup?.link ?
      <Intro visible={ioInView} in={{ bg: 600, fade: 400 }} delayIn={500} mounted={true} stay={true} className="c0 custom-popup t">
        <Link to={props?.popup?.link} className="nostrike"
          onClick={() => { { modalContext.setActive(false); modalContext.setTrigger(old => !old) } }}
          onKeyDown={() => { modalContext.setActive(false); modalContext.setTrigger(old => !old) }}
        >
          <div ref={io} className={`block-login-box c0 fs-85 t ${props.classes}`}>
            <Wysiwyg content={props?.popup?.content} />
            { props?.popup?.formUrl ?
              <iframe
                src={props?.popup?.formUrl}
                frameBorder="0"
                style={{ backgroundColor: '#f0efee', width: '100vw', maxWidth: '100%', padding: '1em 0', margin: '0 auto', minHeight: props?.popup?.formHeight ? props?.popup?.formHeight + 'px' : '500px', height: props?.popup?.formHeight ? props?.popup?.formHeight + 'px' : '500px' }}
                title="Contact Form Event"
              />
              :
              null
            }
          </div>
        </Link>
      </Intro>
      :
      <Intro visible={ioInView} in={{ bg: 600, fade: 400 }} delayIn={500} mounted={true} stay={true} className="c0 custom-popup t">
        <div ref={io} className={`block-login-box c0 fs-85 t ${props.classes}`}>
          <Wysiwyg content={props?.popup?.content} />
          { props?.popup?.formUrl ?
          <iframe
            src={props?.popup?.formUrl}
            frameBorder="0"
            style={{ backgroundColor: '#f0efee', width: '100vw', maxWidth: '100%', padding: '1em 0', margin: '0 auto', minHeight: props?.popup?.formHeight ? props?.popup?.formHeight + 'px' : '500px', height: props?.popup?.formHeight ? props?.popup?.formHeight + 'px' : '500px' }}
            title="Contact Form Event"
          />
          :
          null
        }
        </div>
      </Intro>
  )
}

export default CustomBox