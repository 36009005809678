import React from 'react'
import Seo from '../../components/seo'

import MainFrameCover from '../../components/MainFrameCover'
import Footer from '../../components/Footer'

import Modal from '../../components/Modal'
import SubscribeBox from '../../components/Blocks/SubscribeBox'

import { graphql } from 'gatsby'
import Cookies from '../../components/Cookies'
import Event from '../../components/Event'
import NotFoundPage from '../../pages/404'
import CustomBox from '../../components/Blocks/CustomBox'

function EventTemplate(props) {

  const singleEvent = props?.data?.contentfulEvent

  return (
    singleEvent ?
      <>

        <MainFrameCover />

        <Modal trigger={"#modal-1st-subscribe"}>
          <SubscribeBox />
        </Modal>

        <Modal trigger={"#modal-1st-custom-box"}>
          <CustomBox popup={singleEvent?.popups} />
        </Modal>

        <Cookies />

        <main id="mainframe" className={`c0 layer active main-`}>

          <Seo 
            title={singleEvent?.metaTitle ? singleEvent.metaTitle : singleEvent?.name}
            description={singleEvent?.metaDescription ? singleEvent?.metaDescription : null}
            image={singleEvent?.featuredImage?.file?.url ? singleEvent.featuredImage.file.url : null}
            path={props.location?.pathname ? props.location?.pathname : ''} 
          />

          <Event singleEvent={singleEvent} {...props.params} />

          <Footer />

        </main>
      </>
      :
      <NotFoundPage />
  )
}

export default EventTemplate

export const query = graphql`
query ($slug: String!) {
  contentfulEvent(slug: {eq: $slug}) {
    eventday {
      date
    }
    formUrl
    formHeight
    metaTitle
    metaDescription
    name
    slug
    featuredImage {
      gatsbyImageData(
        quality: 70
        layout: CONSTRAINED
        breakpoints: [300, 500, 750, 1080, 1366, 1920]
        formats: WEBP
      )
      description
      title
      file {
        url
      }
    }
    buttons {
      key
      value
    }
    excerpt {
      raw
    }
    content {
      raw
      references {
        ... on ContentfulBlockButton {
          __typename
          contentful_id
          name
          classes
          label
          link
          size
        }
        ... on ContentfulAsset {
          __typename
          contentful_id
          description
          title
          gatsbyImageData(
            quality: 80
            layout: CONSTRAINED
            breakpoints: [300, 500, 750, 1080, 1366, 1920]
            formats: WEBP
          )
        }
        ...blockVideo
      }
    }
    aside {
      raw
    }
    gallery {
      gatsbyImageData(
        quality: 70
        layout: CONSTRAINED
        breakpoints: [300, 500, 750, 1080, 1366, 1920]
        formats: WEBP
      )
      title
      description
    }
    popups {
        id
        updatedAt
        title
        link
        formUrl
        formHeight
        content {
          raw
          references {
            ... on ContentfulAsset {
              __typename
              contentful_id
              title
              description
              gatsbyImageData(
                quality: 80
                layout: CONSTRAINED
                breakpoints: [300, 500, 750, 1080, 1366, 1920]
                formats: WEBP
              )
            }
            ... on ContentfulBlockButton {
              __typename
              id
              contentful_id
              name
              classes
              label
              link
              size
            }
            ...blockVideo
          }
        }
    }
  }
  contentfulOptions {
    defaultRaceEventImage {
      gatsbyImageData(
        quality: 70
        layout: CONSTRAINED
        breakpoints: [300, 500, 750, 1080, 1366, 1920]
        formats: WEBP
      )
      title
      description
    }
  }
}

`